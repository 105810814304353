<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="grid-content bg-purple">
            <div style="display: inline-block" class="title_h">
              {{ $t("message.new_owner_") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                size="medium"
                :loading="save_loading"
                :disabled="save_loading ? true : false"
                type="success"
                @click="saveData"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="close()">{{ $t("message.close") }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    {{ form }}
    <el-form ref="form" :model="form" :rules="rules" class="aticler_m">
      <el-row :gutter="20">
        <el-col :sm="8">
          <el-form-item :label="columns.address.title" prop="address">
            <el-input v-model="form.address"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="8">
          <el-form-item :label="columns.web_site.title" prop="web_site">
            <el-input v-model="form.website"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="8">
          <el-form-item :label="columns.email.title" prop="email">
            <el-input v-model="form.email"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item :label="columns.image.title">
            <el-upload
              class="upload-demo w-reset m-d-column"
              action="/"
              ref="upload"
              :limit="1"
              :on-change="updateImageList"
              :on-remove="handleRemove"
              list-type="picture-card"
              :file-list="imageList"
              :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :sm="12">
          <el-form-item :label="columns.image.title">
            <el-upload
              class="upload-demo w-reset m-d-column"
              action="/"
              ref="upload"
              :limit="1"
              :on-change="updateImageListHeader"
              :on-remove="handleRemoveHeader"
              list-type="picture-card"
              :file-list="imageListHeader"
              :auto-upload="false"
            >
              <i slot="default" class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <div style="display: inline-block" class="title_h">
          <h5>{{ $t("message.phone_numbers") }}</h5>
        </div>
        <hr />
        <div v-for="(phone_number, index) in phone_numbers" :key="index">
          <el-col :sm="9">
            <el-form-item :label="$t('message.type_phone')">
              <el-input v-model="phone_number.type_name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="9">
            <el-form-item :label="$t('message.phone_number')">
              <el-input
                v-model="phone_number.phone_number"
                v-mask="'+999999999999'"
                placeholder="998(__) ___-__-__"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="3" class="m-d-center">
            <el-form-item label="Касса печать" class="m-d-end">
              <el-checkbox
                style="padding-right: 20%; padding-left: 40%"
                v-model="phone_number.payment_print"
              ></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :sm="2" class="m-d-center">
            <el-button
              size="small"
              class="w-reset m-w-100"
              icon="el-icon-delete"
              style="height: 38px; margin-top: 22px"
              type="danger"
              @click="clear()"
              @click.prevent="removePhoneNumber(index)"
              >{{ $t("message.clear") }}</el-button
            >
          </el-col>
        </div>
      </el-row>
      <div
        class="plusic mt-3"
        @click="addPhoneNumber()"
        style="height: 10%; width: 5%"
      >
        <i class="el-icon-plus"></i>
      </div>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import drawerChild from "@/utils/mixins/drawer-child";
export default {
  mixins: [drawerChild],
  props: ["id"],
  name: "createForm",
  data() {
    return {
      save_loading: false,
      updateImageLists: [],
      imageList: [],
      updateImageListsHeader: [],
      imageListHeader: [],
      loadingData: false,
      form: {},
      phone_numbers: [
        {
          phone_number: "",
          type_name: "",
          payment_print: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      columns: "ownerBranchHeaders/columns",
      rules: "ownerBranchHeaders/rules",
      model: "ownerBranchHeaders/model",
    }),
  },
  created() {
    if (this.model != null) {
      this.form = JSON.parse(JSON.stringify(this.model));
    }
  },
  methods: {
    ...mapActions({
      showHeader: "ownerBranchHeaders/show",
      getOwner: "ownerBranchHeaders/getOwner",
      save: "ownerBranchHeaders/update",
      update: "ownerBranchHeaders/update",
      remove: "ownerBranchHeaders/destroy",
    }),
    handleRemove(file) {
      this.updateImageLists = [];
    },
    updateImageList(file) {
      this.imageList = [];
      this.updateImageLists = [];
      this.imageList.push({ url: file.url });
      this.updateImageLists.push({ url: file.url, raw: file.raw });
    },
    handleRemoveHeader(file) {
      this.updateImageListsHeader = [];
    },
    updateImageListHeader(file) {
      this.imageListHeader = [];
      this.updateImageListsHeader = [];
      this.imageListHeader.push({ url: file.url });
      this.updateImageListsHeader.push({ url: file.url, raw: file.raw });
    },
    async afterOpened() {
      this.loadingData = true;
      this.showHeader(this.id)
        .then((res) => {
          this.imageList = [];
          if (this.form.image != "") {
            this.imageList.push({
              url: this.base_url_docs + "/" + this.form.image,
            });
          }
          this.imageListHeader = [];
          if (this.form.full_logo != "") {
            this.imageListHeader.push({
              url: this.base_url_docs + "/" + this.form.full_logo,
            });
          }
          this.form = JSON.parse(JSON.stringify(this.model));
          this.phone_numbers = res.data.phone_numbers;
          this.loadingData = false;
        })
        .catch((this.loadingData = false));
    },
    afterClosed() {},
    saveData() {
      this.save_loading = true;
      let form = new FormData();
      if (this.updateImageLists.length > 0)
        for (const key in this.updateImageLists) {
          if (this.updateImageLists.hasOwnProperty(key)) {
            const element = this.updateImageLists[key];
            form.append(`image`, element.raw);
          }
        }
      if (this.updateImageListsHeader.length > 0)
        for (const key in this.updateImageListsHeader) {
          if (this.updateImageListsHeader.hasOwnProperty(key)) {
            const element = this.updateImageListsHeader[key];
            form.append(`full_logo`, element.raw);
          }
        }
      this.phone_numbers.forEach((value, index) => {
        value.phone_number.replaceAll("_", "");
      });
      for (const key in this.phone_numbers) {
        if (this.phone_numbers.hasOwnProperty(key)) {
          const element = this.phone_numbers[key];

          for (const el_key in element) {
            if (element.hasOwnProperty(el_key)) {
              const element_in = this.phone_numbers[key][el_key];
              form.append(`phone_numbers[${key}][${el_key}]`, element_in);
            }
          }
        }
      }
      for (var key in this.form) {
        if (this.form[key] != null) {
          if (form.has("image") && key == "image") continue;
          if (form.has("full_logo") && key == "full_logo") continue;
          form.append(key, this.form[key]);
        } else {
          form.append(key, "");
        }
      }

      this.save({ data: form, id: this.form.id })
        .then((res) => {
          this.form = {};
          this.save_loading = false;
          this.resetForm("form");
          this.$notify({
            title: "Успешно",
            type: "success",
            offset: 130,
            message: res.message,
          });
          this.imageList = [];
          this.close();
        })
        .catch((err) => {
          this.save_loading = false;
          this.resetForm("form");
          this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err,
          });
        });
    },
    addPhoneNumber() {
      this.phone_numbers.push({
        phone_number: "",
        type_name: "",
        payment_print: false,
      });
    },
    removePhoneNumber(index) {
      this.phone_numbers.splice(index, 1);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>
<style lang="scss">
.naw_Editor {
  .mce-panel {
    border: 0 solid #c5c5c55e;
  }
}
.block_Editor_Naw {
  padding: 20px;
}
</style>